 <template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex flex-row flex-wrap row-gap-20">
          <h2 class="my-auto col">
            {{ $t(selectedForm.title)  }}
          </h2>
          <!-- <b-button
            class="mt-1 float-right my-auto col-3"
            variant="primary"
            @click="
              () => {
                this.$refs['modal-option-demandes'].show();
              }
            "
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Nouvelle Demande</span>
          </b-button> -->
        </div>

        <hr class="">

        <!-- module de recherche -->
        <!-- <app-collapse>
          <app-collapse-item title="Filtrage et exportation">
          </app-collapse-item>
        </app-collapse> -->
        <!-- filtres -->
        <b-row>
          <!-- recherche par periode-->
          <b-col lg="6">
            <div class="d-flex flex-row-reverse nopadding">
              <b-form-group
                class="col pr-0 pl-0"
                :label="$t('request_manage.filter_label_period')"
              >
                <b-input-group>
                  <DateRangeWidget
                    v-model="filterBy.periode"
                    :range="filterBy.periode"
                    placeholder="Filtrer par date"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <!-- recherche par reference -->
          <b-col lg="6">
            <div class="d-flex flex-row-reverse nopadding">
              <b-form-group
                class="col pr-0 pl-0"
                :label="$t('request_manage.filter_label_reference')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filterBy.reference"
                    type="text"
                    class="form-control"
                    :placeholder="$t('request_manage.filterBy_reference')"
                  />
                  <b-input-group-append
                    is-text
                    @click="
                      () => {
                        filterBy.reference = null;
                        resetListWhenResetFilter();
                      }
                    "
                  >
                    <feather-icon
                      v-b-tooltip.hover.bottom="'Vider le champ!'"
                      icon="XCircleIcon"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <!-- recherche par identity -->
          <b-col lg="6">
            <div class="d-flex flex-row-reverse nopadding">
              <b-form-group
                class="col pr-0 pl-0"
                :label="$t('request_manage.filter_label_code_applicants')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="filterBy.identity"
                    type="text"
                    class="form-control"
                    :placeholder="$t('request_manage.filterBy_identity')"
                  />
                  <b-input-group-append
                    is-text
                    @click="
                      () => {
                        filterBy.reference = null;
                        resetListWhenResetFilter();
                      }
                    "
                  >
                    <feather-icon
                      v-b-tooltip.hover.bottom="'Vider le champ!'"
                      icon="XCircleIcon"
                      class="cursor-pointer"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <!-- recherche par le statut de la demande-->
          </b-col>
          <!-- recherche par status -->
          <b-col lg="6">
            <div class="d-flex flex-row-reverse nopadding">
              <b-form-group
                class="col pr-0 pl-0"
                :label="$t('request_manage.filter_label_status')"
              >
                <b-input-group>
                  <v-select
                    v-model="filterBy.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statuses"
                    :reduce="(option) => option.status"
                    label="etape"
                    :placeholder="$t('request_manage.filterBy_status')"
                    class=""
                  />
                  <!-- <b-input-group-append
                    is-text
                    @click="
                      () => {
                        filterBy.status = null;
                        resetListWhenResetFilter();
                      }
                    "
                  >
                    <feather-icon
                      v-b-tooltip.hover.bottom="'Vider le champ!'"
                      icon="XCircleIcon"
                      class="cursor-pointer"
                    />
                  </b-input-group-append> -->
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>
          <!-- recherche par l'ancienneté de la demande-->
          <b-col lg="6">
            <div class="d-flex flex-row-reverse nopadding">
              <!-- {{options_urgence}} -->
              <b-form-group
                class="col pr-0 pl-0"
                :label="$t('request_manage.filter_label_urgence')"
              >
                <b-input-group>
                  <v-select
                    v-model="filterBy.urgence_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="options_urgence"
                    :reduce="(option) => option.value"
                    label="label"
                    :placeholder="$t('request_manage.filterBy_urgence_status')"
                    class=""
                  />
                </b-input-group>
              </b-form-group>

            </div>
          </b-col>
          <!-- <b-col lg="6">
            <b-form-group
              :label="$t('request_manage.filter_label_code')"
            >
              <b-input-group>
                <b-form-input
                  v-model="filterBy.code_demande"
                  type="text"
                  class="form-control"
                 :placeholder="$t('request_manage.filterBy.code_demande')"
                />
              </b-input-group>
            </b-form-group>
          </b-col> -->
          <b-col lg="12">
            <!-- button -->
            <div class="d-flex justify-content-between ">
              <!-- export button -->
              <b-button
                class="float-right"
                variant="primary"
                @click="exportListDemandes()"
              >
                <feather-icon
                  icon="fileIcon"
                  class="mr-25"
                  v-if="!isLoadingExportData"
                />
                <b-spinner v-else small class="mr-25" />
                <span>{{ $t("button.export") }} (xlsx)</span>
              </b-button>
              <!-- search button -->
              <b-button
                class="float-right"
                variant="warning"
                @click="getListDemandes()"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                />
                <span>{{ $t('button.search') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <!-- list -->
      <b-card class="mt-1">
        <div class="my-1">
          <b-table
            ref="refFormsTable"
            :items="tableData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Aucune donnée"
            :busy="isLoading"
            hover
          >
            <!-- Custom Header -->
            <template #head()="{ label, field: { key, sortable } }">
              {{ $t(label) }}
            </template>
            <!-- busy -->
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <!-- columns -->
            <template #cell(created_at)="data">
              {{ data.item.created_at }} <br>
              <span
                v-if="show_alert(data.item.statuses && data.item.statuses[0])"
                class="ml-1"
              >
                <b-badge
                  pill
                  :variant="
                    getVariantColorByInterval(
                      time_spent_in_days(data.item.created_at),
                      data.item.duration
                    )
                  "
                >
                  {{$t('request_manage.data_item_duration') }}
                  {{ time_spent_in_days(data.item.created_at) }}
                  {{$t('request_manage.data_item_created_at') }}
                </b-badge>
              </span>
            </template>
            <template #cell(statuses)="data">
              <!-- {{ statusMatching(data.item.statuses[0].name) }} -->
              {{
                $t(statusMatching(
                  data.item.process_steps && data.item.process_steps,
                  data.item.statuses && data.item.statuses[0] && data.item.statuses[0].name
                ))
              }}
            </template>
            <template #cell(identity)="data">
              <span>
                <!-- {{ data.item.identity  }}  <br> -->
                Établissement: {{ data.item.user && data.item.user.profile.nom_etablissement }}
              </span>
            </template>
            <template #cell(type_demande)="data">
              {{
                (data.item.type_demande && data.item.type_demande.title) ||
                data.item.type_demande && data.item.type_demande_id
              }}
            </template>
            <template #cell(actions)="data">
              <span class="d-flex flex-row ">
                <!-- can_process_to_treatment: {{ data.item.can_process_to_treatment }} <br> -->
                <!-- should_have_auto_eval: {{ data.item.should_have_auto_eval }}<br> -->
                <!-- auto_evaluation: {{ data.item.auto_evaluation }}<br> -->
                <!-- audit: {{ data.item.audit  }} -->
                <!-- <i
                  v-b-tooltip.hover.top.v-secondary="
                    (!data.item.can_process_to_treatment && !data.item.should_have_auto_eval && !data.item.audit &&
                      $t('request_manage.no_audit')) ||
                      (!data.item.can_process_to_treatment && data.item.should_have_auto_eval && !data.item.auto_evaluation && !data.item.audit &&
                        $t('request_manage.no_audit_and_val')) ||
                      (!data.item.can_process_to_treatment && data.item.should_have_auto_eval && !data.item.auto_evaluation &&
                        $t('request_manage.no_eval')) ||
                      (!data.item.can_process_to_treatment && data.item.should_have_auto_eval && !data.item.audit &&
                        $t('request_manage.no_audit'))
                  "
                  class="las la-info iconBig my-auto mr-1"
                  :class="data.item.can_process_to_treatment ? 'iconGreen' : 'iconRed'"
                /> -->

                <b-button
                  v-b-tooltip.hover.top="$t('request_manage.details_tooltip')"
                  variant="primary"
                  class="mx-0"
                  @click="
                    $router.push({
                      name: 'formulaires-details',
                      params: {
                        id: data.item.id,
                        slug: slug,
                      },
                    })
                  "
                >
                  <i class="las la-eye" />
                </b-button>
              </span>
            </template>
          </b-table>
          <!-- pagination -->
          <div>
            <PaginationComponent
              :table-data="tableData"
              :per-page="paginationData.perPage"
              :total-rows="paginationData.total"
              @change="onPaginationChange"
            />
          </div>
        </div>
      </b-card>

      <!-- modal for notification -->
      <!-- <b-modal
        id="modal-option-demandes"
        ref="modal-option-demandes"
        hide-backdrop
        ok-only
        no-close-on-backdrop
        content-class="shadow"
        title="Quelle opération souhaitez-vous effectuer ?"
        ok-title="Continuer"
        @ok="fillForm()"
      >
        <b-form-group label="">
          <b-form-radio-group v-model="selectedDemandes">
            <b-form-radio
              v-for="(demande, index) in optionsDemandes"
              :key="index"
              name="demande"
              :value="demande"
              class="w-100"
            >
              {{ demande.title }}
              <i
                class="las la-info-circle ml-1"
                v-b-tooltip.hover.top.v-primary="demande.description"
              />
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-modal> -->
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormRadio,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import utilsService from '@/services/utils/utils.service'

import formulairesStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'
import Requests_states from './requests_states.js'

const mockData = {
  formstatagences: [],
  total: 3,
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormRadioGroup,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,
    BFormRadio,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaires', module: formulairesStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { 
      isAdmin, isMtcaAgent, isMtcaAdmin, isOtAdmin, isOtAgent, currentUserId,
      compileUniqueStatuses,
    } = utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isOtAdmin, isOtAgent,
      isMtcaAdmin, isMtcaAgent,
      isAdmin,
      compileUniqueStatuses,
      currentUserId,
      requiredStoreModules,
    }
  },
  props: {
    // slug: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      etablissementsList: [],
      isLoadingEtablissementsList: true,

      loading: false,
      statsLoad: null,

      filterBy: {
        periode: null,
        reference: null,
        code_demande: null,
        identity: null,
        status: null,
        urgence_status: null,
      },
      statuses: null,
      options_urgence: [
        { label: 'Basse', value: 'low' },
        { label: 'Moyennne', value: 'moyenne' },
        { label: 'Elevée', value: 'importante' },
      ],

      periode: null,
      reference: null,
      firstname: null,
      lastname: null,

      curPage: 1,
      perPage: 20,
      query: {
        options: {},
        filter: {},
      },
      tableColumns: [
        {
          key: 'created_at',
          label: 'table_columns.created_at',
          sortable: true,
        },
        {
          key: 'reference',
          label: 'table_columns.reference',
          sortable: true,
        },
        {
          key: 'identity',
          label: 'table_columns.applicants',
          sortable: true,
        },
        {
          key: 'type_demande',
          label: 'table_columns.requests_types',
          sortable: true,
        },
        {
          key: 'statuses',
          label: 'table_columns.status',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'table_columns.actions',
          sortable: true,
          thStyle: { width: '5%' },
        },
      ],
      slug: null,

      tableData: [],
      tableDataCommissariats: [],

      filteredTableData: [],
      form_title: this.$route.params.title || '',
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      isLoading: false,
      
      draggingOver: false,
      model: '',
      useCamera: false,
      loadQR: false,

      optionsStats: [
        { id: 1, label: this.$t('option_stats.label1'), value: this.$t('option_stats.value1') },
        { id: 2, label:this.$t('option_stats.label2'), value: this.$t('option_stats.value2') },
        { id: 3, label: this.$t('option_stats.label3'), value: this.$t('option_stats.value3') },
      ],
      selectedStats: { id: 3, label:  this.$t('option_stats.label3'), value: this.$t('option_stats.value3') },
      isAgentPolice: null,
      isCommissaire: null,
      isSuperAdmin: null,

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),

      optionsDemandes: null,
      selectedDemandes: null,
      code_demande: null,
      
      isLoadingExportData: false,
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        const { slug } = val.params
        this.slug = slug
        this.selectedForm = utilsService.getFormulaireByCodeClient(slug)
        if (!this.selectedForm) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: $t('none'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          // this.goBack();
        }
        this.code_demande = this.selectedForm.code_demande
        this.filterBy.code_demande = this.code_demande
        // -------------------------------------------------
        const { listFormulairesBySector } = utilsService.currentUserUtils()
        this.optionsDemandes = listFormulairesBySector
        this.getListDemandes()
        this.statuses = this.compileUniqueStatuses
        // console.log('this.statuses ::: 🟢🔴🟢', this.statuses );
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        // this.getListDemandes()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        // this.getListDemandes()
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {
    // console.log('⭕️ mounted::: ', this.compileUniqueStatuses);
  },
  methods: {
    ...mapActions('formulaires', {
      action_getLastFormulaireCreatedByUser: 'getLastFormulaireCreatedByUser',
      action_searchFormulaires: 'searchFormulaires',
      action_exportFormulaires: 'exportFormulaires',
      action_fetchFormulaires: 'fetchFormulaires',
      action_addFormulaire: 'addFormulaire',
      action_updateFormulaire: 'updateFormulaire',
    }),
    ...mapActions('etablissements', {
      action_findEtablissement: 'findEtablissement',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('users', {
      action_fetchUsers: 'fetchUsers',
      actions_fetchCommissariats: 'fetchCommissariats',
    }),
    // ------------------------------------------------------------------------------
    getListDemandes() {
      this.isLoading = true
      let params = {
        ...this.filterBy,
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      }
      if(this.isOtAdmin || this.isOtAgent){ // an OT profil 
        params.except_statuses = [
          'on_hold', 'treatment_start', 'form_control',
          'rejected_request',
        ]
      } 
      if(this.isMtcaAgent){ //  an MTCA agent 
        params.statuses = [
          'on_hold', 'treatment_start', 'form_control',
          'rejected_request', 'accepted_request',

          'user_notification_unfavourable_agreement', 
          'user_notification_favourable_agreement_with_conditions',
          'user_notification_favourable_agreement_minister',
          'user_notification_unfavourable_agreement_minister',
          'user_notification_favourable_agreement', 'opening', 'corrections',
          'technical_control_recommendation_check', 'rejection_integration_recommendations',
          'validation_integration_recommendations', 'rejection_opening', 'validation_opening',

          'user_notification_final_classification_fail', 
          'user_notification_final_classification_success',

          'in_classification_process', 'classification_repport_check',
          'user_notification_final_reclassification_fail', 
          'user_notification_final_reclassification_success',
          'reject_succursale_opening', 'accept_succursale_opening',
          'procedure_completion_by_applicant',
        ]
      }
      // console.log('getListDemandes params::: 🟢🟢 🔴', params)
      this.action_searchFormulaires(params)
        .then(res => {
          if (res.status === 200) {
            this.isLoading = false
            this.tableData = []

            // console.log('res.data.data::: 🟢🔴🟢', res.data.data)
            res.data.data.forEach(item => {
              const curr_auto_eval = item.auto_evaluation
              const curr_audit = item.audit
              let selectedForm = null
              let curr_step = null
              selectedForm = utilsService.getFormulaireByCodeDemande(
                item.type_demande && item.type_demande.code,
              )
              if((item.statuses && item.statuses[0] && item.statuses[0].name) && (selectedForm.process_steps && selectedForm.process_steps)){
              curr_step = this.get_current_step(
                selectedForm.process_steps && selectedForm.process_steps,
                item.statuses && item.statuses[0].name,
              )
              }else
              {
                curr_step = this.get_current_step(
                  null,
                null,
              ) 
              }
              const curr_type_demande_code =  item.type_demande && item.type_demande.code
              const should_have_auto_eval = this.should_have_auto_eval_control(curr_type_demande_code)

              const result_can_process_to_treatment = this.can_process_to_treatment(
                curr_audit,
                should_have_auto_eval,
                curr_auto_eval,
              )

              this.tableData.push({
                ...item.data,
                user: item.user,
                user_id: item.user_id,
                id: item.id,
                statuses: item.statuses && item.statuses,
                created_at: item.created_at,
                updated_at: item.updated_at,
                duration:item.type_demande && item.type_demande.duration,
                type_demande: item.type_demande && item.type_demande,
                can_process_to_treatment: result_can_process_to_treatment,
                should_have_auto_eval,
                auto_evaluation: item.auto_evaluation,
                audit: item.audit,
                reference: item.reference,
                curr_step,
                process_steps: selectedForm.process_steps && selectedForm.process_steps,
              })
            })
            // console.log('this.tableData::: 🟠🟢🔴🟢', this.tableData)

            // --------------------------------------------------------------
            this.paginationData.total = res.data.meta.total || 0
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
                - this.paginationData.perPage
            }
            this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
              + res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    exportListDemandes() {
      this.isLoadingExportData = true
      const params = {
        ...this.filterBy,
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
        export: true
      }
      // console.log('getListDemandes params::: 🟢🔴🟢', params)
      this.action_exportFormulaires(params)
        .then((response) => {
          this.isLoadingExportData = false;
          // console.log("response action_exportFormulaires::: ⭕️", response.data);
          const blob = new Blob([response.data], { type: 'text/xlsx' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Demandes.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Exportation réussie",
              icon: "CheckIcon",
              variatnt: "success",
            },
          });
        })
        .catch(err => {
          console.log(err)
          this.isLoadingExportData = false
        })
    },
    resetListWhenResetFilter() {
      this.filterBy = {
        periode: null,
        reference: null,
        code_demande: this.selectedForm.code_demande,
        identity: null,
        status: null,
        urgence_status: null,
      }
      this.getListDemandes()
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val }
    },
    getVariantColorByInterval(number, basic_duration) {
      const duration = (basic_duration /= 24)
      if (number >= 0 && number <= (duration / 3) * 2) {
        return 'primary'
      }
      if (number > (duration / 3) * 1 && number <= duration) {
        return 'warning'
      }
      return 'danger'
    },
    show_alert(last_status) {
      if(last_status)
      return (
        last_status.name !== 'treatment_completed_successfully'
        && last_status.name !== 'treatment_completed_failure'
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
